/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */


(function ($) {


  /*
  *  add_marker
  *
  *  This function will add a marker to the selected Google Map
  *
  *  @type	function
  *  @date	8/11/2013
  *  @since	4.3.0
  *
  *  @param	$marker (jQuery element)
  *  @param	map (Google Map object)
  *  @return	n/a
  */

  function add_marker($marker, map) {

    // var
    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      map: map
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($marker.html()) {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html()
      });

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function () {

        infowindow.open(map, marker);

      });
    }

  }

  /*
  *  center_map
  *
  *  This function will center the map, showing all markers attached to this map
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 4.3.0
  *
  *  @param map (Google Map object)
  *  @return  n/a
  */

  function center_map(map) {

    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function (i, marker) {

      var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

      bounds.extend(latlng);

    });

    // only 1 marker?
    if (map.markers.length === 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
      map.setZoom(16); // Set Zoom of the Map! IMPORTANT!
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }

  }

  /*
  * add_custom Style
  *
  * @date 17/03/2015
  * since 1.0
  *
  * © carol burri
  */

  var featureOpts = [
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e9e9e9"
        },
        {
          "lightness": 17
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        },
        {
          "lightness": 20
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 17
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 29
        },
        {
          "weight": 0.2
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 18
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "lightness": 16
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        },
        {
          "lightness": 21
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dedede"
        },
        {
          "lightness": 21
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "color": "#ffffff"
        },
        {
          "lightness": 16
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "saturation": 36
        },
        {
          "color": "#333333"
        },
        {
          "lightness": 40
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f2f2f2"
        },
        {
          "lightness": 19
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#fefefe"
        },
        {
          "lightness": 20
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#fefefe"
        },
        {
          "lightness": 17
        },
        {
          "weight": 1.2
        }
      ]
    }
  ];

  /*
  *  render_map
  *
  *  This function will render a Google Map onto the selected jQuery element
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 4.3.0
  *
  *  @param $el (jQuery element)
  *  @return  n/a
  */

  function render_map($el) {
    // var
    var $markers = $el.find('.marker');

    // vars
    var args = {
      zoom: 0,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMA,
      styles: featureOpts,
      disableDefaultUI: true
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function () {

      add_marker($(this), map);

    });

    // center map
    center_map(map);
  }


  // Basic Mobile menu
  var MobileMenu = function () {
    $('.spaceTop-Mobile').css({'border-bottom': '3px solid black'});
    // Mobile Navigation
    $('.nav-burger').click(function () {
      $(this).toggleClass('open');
      $('.mobile-hidden').toggleClass('show');
      $('.mobile-show').fadeToggle();
    });
    $('.nav-burger').on('click', function () {
      if ($("header").height() == 40) {
        $("header").height(244);
        $('.spaceTop-Mobile').css({'border-bottom': 'none'});
      } else if ($("header").height() == 244) {
        $("header").height(40);
        $('.spaceTop-Mobile').css({'border-bottom': '3px solid black'});
      }
    });
  }

  var AccordionMenu = function () {
    // Mobile Menu if Accordion is Open
    $('.nav-burger').on('click', function () {
      var $mobileHeight = $('header').height();
      if ($("header").height() == 37) {
        $('header').removeClass('mini');
        $('.header-height').removeClass('mini');
        $("header").height(244);
        $('.spaceTop-Mobile').css({'border-bottom': 'none'});
      } else if ($(".header").height() == 200) {
        $("header").height(40);
        $('.spaceTop-Mobile').css({'border-bottom': '3px solid black'});
      }
    });
  }

  var AccordionOpen = function () {
    $('.spaceTop-Mobile').css({'border-bottom': 'none'});
    $('header').addClass('mini');
    $('footer').addClass('extend');
    $('.mobile-show').show();
    $('header').css({'min-height': '40px', 'height': '40px'});
    $('.nav-burger').removeClass('open'); // Close Mobile-Menu
    $('.mobile-hidden').removeClass('show'); // Close Mobile-Menu
  }

  var AccordionBorder = function () {
    $('.spaceTop-Mobile').css({'border-bottom': '3px solid black'});
  }

  var AccordionClose = function () {
    $('.spaceTop-Mobile').css({'border-bottom': '3px solid black'});
    $('header').css({'minHeight': '40px', 'height': '40px'});
    $('.header-height').css({'minHeight': '35px', 'height': '35px'});
    if ($("header").height() == 244) {
      $('header').css({'minHeight': '40px'});
    } else {
      $('header').removeClass('mini');
      $('footer').removeClass('extend');
      $('header').css({'minHeight': '40px'});
    }
  }

  var ScreenAccordionOpen = function () {
    if (window.matchMedia('(min-width: 767px)').matches) {
      $('.wrap').css({'margin-top': '40px'});
      $('.header-height').css({'height': '35px', 'min-height': '35px'});
      $('header').addClass('mini');
    }
    ;
  }

  var ScreenAccordionClose = function () {
    if (window.matchMedia('(min-width: 767px)').matches) {
      $('.wrap').css({'margin-top': '90px'});
      $('.header-height').css({'height': '80px', 'min-height': '80px'});
    }
    ;
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {

        MobileMenu();

        // This script fixes the shift that occurs in a centered layout
        // when the page grows and forces scrollbars to appear.
        $(function () {
          var body = $("body");

          var previousWidth = null;

          // Function that applies padding to the body
          // to adjust its position.
          var resizeBody = function () {
            var currentWidth = body.width();
            if (currentWidth != previousWidth) {
              previousWidth = currentWidth;

              // Measure the scrollbar size
              body.css("overflow", "hidden");
              var scrollBarWidth = body.width() - currentWidth;
              body.css("overflow", "auto");

              body.css("margin-left", scrollBarWidth + "px");
            }
          };

          // setInterval is required because the resize event
          // is not fired when a scrollbar appears or disappears.
          setInterval(resizeBody, 100);
          resizeBody();
        });


        // initialize google maps
        $('.acf-map').each(function () {

          render_map($(this));

        });

        /*
        // mini Header
        $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 5) {
        $('header').addClass('mini');
        $('.header-height').addClass('mini').css({'minHeight': 'auto'});
        $('footer').addClass('extend');
      } else {
      $('header').removeClass('mini');
      $('.header-height').removeClass('mini');
      $('footer').removeClass('extend');
      $('.header-height').css({'minHeight': '40px'});
    }
  });
  */
      }
    },
// Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
// About us page, note the change from about-us to about_us.
    'office': {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {

        AccordionMenu();

        //Scroll To Top
        $('.panel-collapse').on('shown.bs.collapse', function (e) {
          AccordionOpen();
          AccordionBorder();
          ScreenAccordionOpen();
          var $panel = $(this).closest('.panel');
          var $headerHeight = $('.header-height').height();
          setTimeout(function () {
            if (window.matchMedia('(min-width: 767px)').matches) {
              $('html,body').animate({
                scrollTop: $panel.offset().top - 40
              }, 500);
            } else {
              $('html,body').animate({
                scrollTop: $panel.offset().top - 40
              }, 500);
            }
          }, 120);
        });


        $('.panel-collapse').on('hidden.bs.collapse', function (e) {
          AccordionClose();
          ScreenAccordionClose();
        });


        // Toggle Former
        $('.toggleFormer').find('.hideFormer').hide();
        $('.panelFormer').click(function () {
          $(this).next().slideToggle('fast');
          $(this).find('.iconTwo').toggleClass('iconTwo-close iconTwo-open');
        });

        $('.icons').on('hidden.bs.collapse', function () {
          $(this).parent().find('.icon').removeClass('icon-close')
        }).on('shown.bs.collapse', function () {
          $(this).parent().find('.icon').addClass('icon-close')
        });
        $('.plusminus').on('hidden.bs.collapse', function () {
          $(this).parent().find('.iconTwo').removeClass('iconTwo-close').addClass('iconTwo-open')
        }).on('shown.bs.collapse', function () {
          $(this).parent().find('.iconTwo').addClass('iconTwo-close').removeClass('iconTwo-open')
        });
      }
    },
    'projects': {
      init: function () {

      },
      finalize: function () {

        // Add Current Class to Filter
        $('.iconsX').on('hidden.bs.collapse', function () {
          $(this).parent().find('.icon').removeClass('icon-close');
        }).on('shown.bs.collapse', function () {
          $(this).parent().find('.icon').addClass('icon-close');
          $('.spaceTop-Mobile').css({'border-bottom': 'none'});
        });

        var sidenav = $('ul.nav-drop');
        sidenav.delegate('li.nav-drop-item', 'click', function (e) {
          e.preventDefault();
          sidenav.find('.active').toggleClass('active');
          $(this).toggleClass('active');
        })
          .find('li:first').addClass('active');

        // Toggle More Text
        $('.toggle').find('.hide').hide();
        $('.icons').click(function () {
          $(this).toggleClass('icon-close icon-open').children('.hide').slideToggle('fast');
        });

        //Hide and Show Elements
        $('.tableContent').hide();
        $('.Selected').show();
        $('a[href="#selected"]').click(function () {
          $('.tableContent').hide();
          $('.Selected').show();
        });
        $('a[href="#all"]').click(function () {
          $('.tableContent').show();
        });

        //Image Slider
        function doit() {
          $('#myCarousel').removeAttr('id', 'myCarousel');
          $('.carousel-item.active').removeClass('active');

          $('a[data-slide="prev"]').click(function () {
            console.log('prev');
            $('#myCarousel').carousel('prev');
          });

          $('a[data-slide="next"]').click(function () {
            console.log('next');
            $('#myCarousel').carousel('next');
          });
        };

        //Accordion
        $('.collapse').collapse('hide');

        $('#accordion').on('show.bs.collapse', (event) => {
          doit();
          AccordionOpen();
          ScreenAccordionOpen();
          $(event.target).find('.carousel').attr('id', 'myCarousel');
          $(event.target).find('.carousel-item').first().addClass('active');
        });

        //Scroll To Top
        $('.panel-collapse').on('shown.bs.collapse', function (e) {
          AccordionBorder();
          ScreenAccordionOpen();
          var $panel = $(this).closest('.panel');
          var $headerHeight = $('.header-height').height();
          setTimeout(function () {
            if (window.matchMedia('(min-width: 767px)').matches) {
              $('html,body').animate({
                scrollTop: $panel.offset().top - 40
              }, 500);
            } else {
              $('html,body').animate({
                scrollTop: $panel.offset().top - 40
              }, 500);
            }
          }, 120);
        });

        // Mobile Menu if Accordion is Open
        AccordionMenu();

        $('.panel-collapse').on('hidden.bs.collapse', function (e) {
          AccordionClose();
          ScreenAccordionClose();
        });
        // Swipe on Mobile
        $('.carousel').bcSwipe({threshold: 50});
        // Toggle Arrow on ID
        var filterArrow = $('.tops');
        $(filterArrow).children('.id').find('span:first').addClass('arrowDown');


        // var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        //Sorting
        var sorting = 1;
        $(".tops div").each(function () {
          $(this).click(function () {
            var cl = $(this).attr("class");
            var type = $(this).attr("data-type") || 'unknown';
            sorting = sorting == 1 ? -1 : 1;
            $(this).parent().find('div > span').removeClass('arrowDown arrowUp');
            $(this).children().addClass(sorting === 1 ? 'arrowDown' : 'arrowUp');

            function table_sort(a, b) {
              var str1 = $(a).find('.' + cl).html();
              var str2 = $(b).find('.' + cl).html();
              if (sorting < 0) {
                var t = str1;
                str1 = str2;
                str2 = t;
              }
              return $.isNumeric(str1) && $.isNumeric(str2) ? parseInt(str1) - parseInt(str2) : str1.toString().localeCompare(str2.toString());
            }

            $(".tableContent").sort(table_sort).appendTo($(".tops").parent());
          });
        });
      }
    }
  };

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

// Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
